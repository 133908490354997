import React from "react";
import _ from "lodash";
import Expertise from '../components/Expertise'
import Icon from '../components/Icon';
import IllustrationData from '../media/illustration/Analytics.png';

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'analytics-data-science',
      title: "Accélérez vos projets data et IA en santé",
      description: "Bénéficiez d'une expertise en data science et analytics adaptée au monde de la santé.",
      content:
        <div class="columns">
            <div class="column section-panel is-8">
                <div class="content is-medium">
                  <h1 class="title">Débloquez le potentiel de la donnée de santé</h1>
                  <p>Compte rendus médicaux, génomiques ou encore essais cliniques, la donnée 
                    de santé regorgent de nombreuses facettes, de formats et de biais qu'il faut savoir interpréter.</p>
                  <p>Chez Akimed, nous sommes convaincus qu'une bonne connaissance de 
                    la donnée ne suffit pas, il faut également avoir de solides connaissances médicales.</p>
                  <p>C'est la raison pour laquelle nos équipes sont à la fois 
                    composés <b>de data scientists et de professionnels de santé</b> pour choisir et valider
                    les hypothèses pertinentes.</p>
                  <p>Quelques exemples de projets sur lesquels nous avons travaillé dernièrement :</p>
                  <ul>
                    <li>Création de dashboard pour le pilotage des essais cliniques d'un groupe pharmaceutique par la donnée</li>
                    <li>Structuration de comptes rendus médicaux avec le NLP (Natural Language Processing) et le NER (Named Entity Recognition)</li>
                    <li>Réalisation d'études observationnelles sur des maladies rares et en oncologie</li>
                  </ul>
                  <p>Les technologies que nous utilisons quotidiennement :</p>
                  <ul>
                    <li><b>Python, Pandas et Dask</b> - Le langage et librairies de prédilection pour les projets d'IA et d'analyse de données.</li>
                    <li><b>Tableau, PowerBI, Dash</b> - Réaliser des dashboards dynamiques pour l'étude de vos données.</li>
                    <li><b>SQL</b> - Le langage pour requêter les bases de données.</li>
                  </ul>
                </div>
            </div>
            <div class="column section-panel is-3 is-offset-1">
                <figure class="image">
                <Icon src={IllustrationData} />
                </figure>
            </div>
        </div>
    };
  }

  render() {
    return (
        <Expertise
          content={this.state.content}
          activePage={this.state.page} 
          title={this.state.title}
          description={this.state.description}
        />
    );
  }
}
